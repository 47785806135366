<template>
  <div>
    <div class="backsOut">
      <div class="backs">
        <img src="@/assets/images/fh.png" alt="" @click="goback" />
        <span>协议支付</span>
      </div>
    </div>
    <div class="ment">
      <div class="erds" @click="danbis">
        <img src="@/assets/images/danbi.png" alt="" />
        <span>协议单笔支付</span>
      </div>
      <div class="erds" @click="fenqis">
        <img src="@/assets/images/fenqi.png" alt="" />
        <span>协议分期支付</span>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  methods: {
    // 单笔支付
    danbis() {
      this.$router.push("/withholdData");
    },
    // 分期支付
    fenqis() {
      this.$router.push("/multiple");
    },
    goback() {
      this.$router.push("/home");
    },
  },
};
</script>

<style lang="scss" scoped>
.ment {
  display: flex;
  width: 100%;
  flex-wrap: wrap;
  flex-direction: space-around;
  .erds {
    display: flex;
    width: 33%;
    height: 5rem;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    img {
      width: 2.5rem;
      height: 2.5rem;
    }
    span {
      margin-top: 0.5rem;
      font-size: 0.8rem;
    }
  }
}
</style>